import { useApi } from '@tenant/composables'

export type AccountTypesState = {
    accountTypes: string[]
}

export default {
    state: (): AccountTypesState => ({
        accountTypes: [],
    }),

    mutations: {
        setAccountTypes(state: AccountTypesState, accountTypes: []) {
            state.accountTypes = accountTypes
        },
    },

    getters: {
        accountTypes(state: AccountTypesState) {
            return state.accountTypes
        },
    },

    actions: {
        fetchAccountTypes({ commit }: { commit: (...args: any[]) => void }) {
            const { execute } = useApi('/api/register-account-type', 'GET')

            execute().then((items) => {
                commit('setAccountTypes', items)
            })
        },
    },
}
